
import './App.css';
import {  BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import ContactUs from './pages/ContactUs';
import OurWork from './pages/ourWork';
import Services from './pages/services';
import Footer from './components/common/footer';
import Header from './components/common/header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
      <Route  exact path="/" element={<Home/>} />
      <Route  path="/about" element={<About/>} />
      <Route  path="/our-work" element={<OurWork/>} />
      <Route  path="/services" element={<Services/>} />
      <Route  path="/contact-us" element={<ContactUs/>} />
      {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
      <Footer/>
  </Router>
  );
}

export default App;
