import React from 'react'

const ContactDetails = () => {
  return (
    <section className="space-section">
		<div className="container">
			<div className="row mb-4">
				<div className="col-md-4">
					<div className="counter-box">
						{// eslint-disable-next-line
						<img src="/images/envelope.png" className="img-fluid" alt='envelop'/>}
						<p><b>Business@srnext.in</b></p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="counter-box">
						{// eslint-disable-next-line
						<img src="/images/phone-color.png" className="img-fluid" alt='phone'/>}
						<p><b>+919109109148</b></p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="counter-box visibity-none">
						{// eslint-disable-next-line
						<img src="/images/skype-colored.png" className="img-fluid" alt='skpye'/>}
						<p><b>Skype</b></p>
					</div>
				</div>
				<div className="col-md-12 text-center mt-3">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.348941985954!2d75.89154127957613!3d22.752428126155525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302aa57f7f1a7%3A0xe1bb786ea4f96664!2sMangal%20City%20Mall!5e0!3m2!1sen!2sin!4v1727869391094!5m2!1sen!2sin" width="100%" height="450" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</div>
	</section>
  )
}

export default ContactDetails
