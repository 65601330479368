import React from 'react'
import Card from '../../common/card'

const OurValues = () => {
const valuesData = [
		{
			id: 1,
			name: 'Top performance in tasks.',
			description:'We prioritize understanding our clients to deliver tailored software solutions.',
			imageUrl:"/images/pencil.png"
		},
		{
			id: 2,
			name: 'Integrity',
			description:'Guided by integrity, we respect and value significant contributions, striving to create prominent partnerships.',
			imageUrl:"/images/interigity.png"
		},
		{
			id: 3,
			name: 'Progressive Learning',
			description:'We motivate and foster innovation, nurturing individual curiosity to propel anticipated future successes.',
			imageUrl:"/images/layer.png"
		},

	]
  return (
    <section className="space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center mb-4">
					<h1 className="heading">Our Values <span>.</span></h1>
					<p>Our fundamental principles are shaped by the unique personalities of our <br/> team members, as reflected in their work habits and professional choices.</p>
				</div>
				{valuesData && valuesData.map((vData)=>(
					<div className="col-md-4 d-flex" key={vData.id}>
						<Card data={vData}/>
					</div>
				))}
				
			</div>
		</div>
	</section>
  )
}
export default OurValues