import React from 'react'

const ErpSolution = () => {
  return (
    <section className="services-box space-section bg-gray">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-6">
            <h6 className="vision-title">Backend | Frontend | Webapp</h6>
            <h5 className="methodology-title">ERP Solution.</h5>
            <h4 className="demand-delivery">Lifestyle</h4>
            <h6 className="outcome-title">About</h6>
            <p>It is a platform for efficiently creating and managing electronic data capture systems in clinical trials, enhancing accuracy, compliance, and speed in data collection.</p>
            <h6 className="outcome-title">Project Outcomes</h6>
            <div className="outcome-description">
              <p>Optimized workflows led to a 35% boost in productivity.</p>
            </div>
            <div className="outcome-description">
              <p>Enhanced software architecture improved performance by 25%.</p>
            </div>
            <div className="outcome-description">
              <p>Implemented robust validation processes, reducing errors by 40%.</p>
            </div>
            <div className="outcome-description">
              <p>Resource management resulted in 20% cost savings for clients.</p>
            </div>
            
          </div>
          <div className="col-md-6 text-center">
            <div className="service-slide">
              {
                // eslint-disable-next-line
                <img src="/images/erpsolution.png" className="img-fluid w-70" alt='erp'/>}
            </div>
          </div>
        </div>	
      </div>
    </section>
  )
}

export default ErpSolution
