import React from 'react'
import WorkModels from '../components/our-work-page/work-models'
import ECommerce from '../components/our-work-page/e-commerce'
import ErpSolution from '../components/our-work-page/erp-solution'
import DataMangement from '../components/our-work-page/ data-mangement'
import FitnessApplication from '../components/our-work-page/fitness-application'
import Banner from '../components/common/banner'

const OurWork = () => {
  const bannerData = {
    headingOne: 'Case studies',
    headingTwo: '<b>Explore Our Recent <span className="text-red">Work.</span></b>',
    description:'<p>People expect more immersive experiences than ever. At SR NEXT, we combine customer insights with <b>cutting-edge design</b> to create solutions that capture attention and emphasize every detail. We make the experience <b>seamless</b> and unforgettable. </p>',
    buttonName: 'Hire our Developers',
    imageUrl:'/images/iso-certificate.png',
        className:"our-work"
  }
  return (
    <>
    <Banner bannerData={bannerData}/>
    <WorkModels/>
    <ECommerce/>
    <DataMangement/>
    <ErpSolution/>
    <FitnessApplication/>
      
    </>
  )
}

export default OurWork