import React from 'react'
import ContactForm from '../contact-form'

const FooterForm = () => {
  return (
    <section className="space-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <ContactForm/>
            </div>
        </div>
    </div>
</section>
  )
}

export default FooterForm
