import React from 'react'
import Card from '../../common/card'

const WorkModels = () => {
  const workData = [
    {
      id: 1,
      name:'Fixed Price Model',
      description:'The fixed-price model suits short-term projects with clear requirements.',
      imageUrl:"/images/dollar.png"
    },
    {
      id: 2,
      name:'Time and Material Model',
      description:'This cost-effective model is ideal for long-term projects with evolving requirements, balancing resources, workload, and market demands.',
      imageUrl:"/images/time-fast.png"
    },
    {
      id: 3,
      name:'Dedicated Development Hub',
      description:'This is ideal for projects needing dedicated tech staff and development teams, with the added benefit of cross-border expertise.',
      imageUrl:"/images/network.png"
    }
  ]
  return (
    <section className="space-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">Flexible <span> Work Models </span> Tailored to <br/> Your Needs.</h1>
            <p className="text-center mb-5 sub-heading">Our performance engineering team offers tailored <br/> engagement models for efficient product launches.</p>
          </div>
        </div>
        <div className="row">
         {workData && workData.map((wData)=>(
          <div className="col-md-4 d-flex" key={wData.id}>
            <Card data={wData} isCenter={true}/>
          </div>
         )) }
        </div>
      </div>
    </section>
  )
}

export default WorkModels
