import React from 'react'
import Services from '../components/home-page/services'
import LatestCase from '../components/home-page/latest-case'
import DeliverSolutions from '../components/home-page/deliver-solutions'
import BookACall from '../components/home-page/book-a-call'
import Erp from '../components/home-page/erp'
import OurClients from '../components/common/our-clients'
import CountersSection from '../components/home-page/counter'
import Banner from '../components/common/banner'

const Home = () => {
  const bannerData = {
    headingOne: 'Go for massive returns with a bold vision!',
    headingTwo: '<b>Custom Software <br/> Development</b>',
    description:'<p>Partner with our exceptional team, specialising in <b> Mobile App </b> and <b> Web Development,</b> to achieve excellence.</p><p>Witness a digital business revolution firsthand!</p>',
    buttonName: 'Get in Touch',
    imageUrl:'/images/iso-certificate.png',
    className:'slider-img'
  }
  return (
    <>
    <Banner bannerData={bannerData}/>
    <Services/>
    <CountersSection/>
    <LatestCase/>
    <DeliverSolutions/>
    <BookACall/>
    <Erp/>
    <OurClients/>
    </>
  )
}

export default Home
