import React from 'react'
import InnovativeServices from '../components/services-page/innovative-services'
import OurPotential from '../components/services-page/our-potential'
import Ideas from '../components/services-page/ideas'
import OurClients from '../components/common/our-clients'
import Banner from '../components/common/banner'

const Services = () => {
  const bannerData = {
    headingOne: 'Our offerings',
    headingTwo: 'Innovate & Ideate Our <b>Comprehensive Service</b> Offerings.',
    description:'<p>Implementing bespoke, customer-focused website solutions that transform businesses into brands within the dynamic digital environment.</p>',
    buttonName: 'Book a call',
    imageUrl:'/images/iso-certificate.png',
    className: 'services-bg'
  }
  return (
    <>
      <Banner bannerData={bannerData}/>
      <InnovativeServices/>
      <OurPotential/>
      <Ideas/>
      <OurClients/>
    </>
  )
}

export default Services