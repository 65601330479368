import ContactBanner from '../components/contact-us-page/contact-banner'
import ContactDetails from '../components/contact-us-page/contact-details'
import React from 'react'

const ContactUs = () => {
  return (
    <>
      <ContactBanner/>
      <ContactDetails/>
    </>
  )
}

export default ContactUs