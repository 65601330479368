import React from "react";
import ImageBox from "../../common/image-box";

const InnovativeServices = () => {
  const innovativeData = [
    {
      id: 1,
      name: "Software Engineering",
      description:
        "<p>We offer end-to-end custom software development services, including the hiring of dedicated developers and teams.</p>",
      imageUrl: "images/7.png",
    },
    {
      id: 2,
      name: "Digital Automation",
      description:
        "<p>We transform and automate your traditional business processes with advanced, scalable technologies.</p>",
      imageUrl: "/images/8.png",
    },
    {
      id: 3,
      name: "Intelligence Automation",
      description:
        "<p>Unlock our intelligent automation services to drive innovation and growth with real-time decision-making.</p>",
      imageUrl: "/images/9.png",
    },
  ];
  return (
    <section className="services-box space-section">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-12 text-center">
            <h1 className="heading">
              Innovative Services. Real <span> Growth </span>
              <br /> Delivered<span>.</span>{" "}
            </h1>
            <p>
              Explore our extensive range of services designed <br /> to help
              you make a lasting impact for a better future.
            </p>
          </div>
        </div>
        <div className="row">
          {innovativeData &&
            innovativeData.map((iData) => (
              <div className="col-md-4" key={iData.id}>
                <ImageBox data={iData} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default InnovativeServices;
