import React, { useState } from 'react';

const ContactForm = ({ isLight = false }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);  // Loading state
  const [errors, setErrors] = useState({}); // Error state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // Clear the error message for the field being edited
    setErrors({
      ...errors,
      [e.target.name]: '', // Clear the error for the field being edited
    });
  };

  const validateForm = () => {
    const newErrors = {};
    // Validation rules
    if (!formData.name) {
      newErrors.name = 'Full Name is required.';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email format is invalid.';
    }
    if (!formData.message) {
      newErrors.message = 'Project Description is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop form submission if there are validation errors
    }

    setLoading(true); // Start loading when form is submitted

    // Create a FormData object to handle form data
    const form = new FormData();
    form.append('name', formData.name);
    form.append('email', formData.email);
    form.append('message', formData.message);

    try {
      const response = await fetch('https://srnext.in/backend/index.php', {
        method: 'POST',
        body: form, // FormData object is automatically sent as multipart/form-data
      });
     
      const result = await response.json();
      if (result.status) {
        setSuccessMessage('Thank you for reaching out! We will get back to you soon.')
        // Reset form data and errors
        setFormData({ name: '', email: '', message: '' });
        setErrors({});
        setTimeout(() => setSuccessMessage(''), 5000);
      } else {
        setErrorMessage('There was an error submitting the form. Please try again.')
        setTimeout(() => setErrorMessage(''), 5000);
      }
    } catch (error) {
      setErrorMessage('There was an error submitting the form. Please try again.')
      setTimeout(() => setErrorMessage(''), 5000);
    } finally {
      setLoading(false); // Stop loading when request is completed
    }
  };

  return (
    <div className={`contact-us ${isLight ? 'bg-light' : ''}`}>
      {successMessage && <div className="alert alert-success alert-dismissible fade show success_msg" role="alert">
        <strong>{successMessage}</strong>
        <button type="button" onClick={() => setSuccessMessage(false)} className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>}
      {errorMessage && <div className="alert alert-danger alert-dismissible fade show success_msg" role="alert">
        <strong>{errorMessage}</strong>
        <button type="button" onClick={() => setErrorMessage(false)} className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading text-left">
              Do you have a project <br /> in mind<span>?</span>
            </h1>
          </div>

          {/* Full Name */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Full Name<span className='text-danger'>*</span></label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control"
              />
              <div className="hr-line"></div>
              {errors.name && <small className="text-danger">{errors.name}</small>} {/* Show error if exists */}
            </div>
          </div>

          {/* Project Description */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Project Description<span className='text-danger'>*</span></label>
              <input
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="form-control"
              />

              <div className="hr-line"></div>
              {errors.message && <small className="text-danger">{errors.message}</small>} {/* Show error if exists */}
            </div>
          </div>

          {/* Email */}
          <div className="col-md-6">
            <div className="form-group">
              <label>Email<span className='text-danger'>*</span></label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
              />

              <div className="hr-line"></div>
              {errors.email && <small className="text-danger">{errors.email}</small>} {/* Show error if exists */}
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-md-6">
            <div className="form-group">
              <button type="submit" className="btn btn-orange m-t-30" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit'} {/* Show loader or Submit */}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;