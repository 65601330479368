import React from 'react'

const DeliverSolutions = () => {
	const delivarData= [
		{
			id:'1',
			imageUrl:'/images/rails.png'
		},
		{
			id:'2',
			imageUrl:'/images/react.png'
		},
		{
			id:'3',
			imageUrl:'/images/java.png'
		},
		{
			id:'4',
			imageUrl:'/images/python.png'
		},
		{
			id:'5',
			imageUrl:'/images/php.png'
		},
		{
			id:'6',
			imageUrl:'/images/mysql.png'
		},
		{
			id:'7',
			imageUrl:'/images/node.png'
		},
		{
			id:'8',
			imageUrl:'/images/laravel.png'
		}
	]
  return (
    <section  className="space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
					<h1 className="heading">We deliver <span> solutions </span> built on <br/>Trust<span>.</span></h1>
					<p>Our technology index.</p>
				</div>
				<div className="col-md-12">
					<ul className="icon-list">
						{delivarData && delivarData.map((dData)=>(
							<li key={dData.id}>
								<div className="technology-logo">
									{// eslint-disable-next-line
									<img src={dData.imageUrl} className="img-fluid" alt={dData.id} />}
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	</section>
  )
}

export default DeliverSolutions
