import React from 'react'

const OurMethodology = () => {
  return (
    <section className="services-box space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<h1 className="heading">Our Methodology.</h1>
					<p className="text-center mb-5 sub-heading">Exploring Our Streamlined and Intelligent Approach <br/> to Mobile App and Web Development.</p>
				</div>
			</div>
			<div className="row d-flex align-items-center justify-content-center">
				<div className="col-md-5">
					<h5 className="methodology-title">Moving from Agility to Action: <br/> Achieve Immediate and Personalized Value on a Large Scale.</h5>
					<p>We use Agile to boost communication, improve collaboration, adapt to change, and consistently deliver value, ensuring efficient development and strong returns on investment.</p>
				</div>
				<div className="col-md-5 text-center">
					<div className="service-slide">
						{// eslint-disable-next-line
						<img src="/images/methodology.png" className="img-fluid w-50" alt='methodology'/>}
					</div>
				</div>
				
			</div>	
		</div>
	</section>
  )
}

export default OurMethodology
