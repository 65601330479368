import React from "react";

const FitnessApplication = () => {
  return (
    <section className="services-box space-section">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-6">
            <h6 className="vision-title"> </h6>
            <h5 className="methodology-title">Fitness Application.</h5>
            <h4 className="demand-delivery">Lifestyle</h4>
            <h6 className="outcome-title">About</h6>
            <p>
              Thenx is a top platform for calisthenics, offering bodyweight
              workout programs and videos accessible online and via app.
            </p>
            <h6 className="outcome-title">Project Outcomes</h6>
            <div className="outcome-description">
              <p>20% Efficiency Gain.</p>
            </div>
            <div className="outcome-description">
              <p>
                Enhanced user experiences. 15% rise in customer satisfaction.
              </p>
            </div>
            <div className="outcome-description">
              <p>Accelerated development cycles by 25%.</p>
            </div>
            <div className="outcome-description">
              <p>Enabled clients to gain a 10% increase in market share.</p>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <div className="service-slide">
              {
                // eslint-disable-next-line
                <img
                  src="/images/fitness.png"
                  className="img-fluid w-70"
                  alt="fitness"
                />
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FitnessApplication;
