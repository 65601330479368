import React from "react";
import { useState, useEffect } from "react";
const Counter = ({ endValue, duration, suffix }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const incrementTime = duration / endValue;

    const counter = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === endValue) {
        clearInterval(counter);
      }
    }, incrementTime);

    return () => clearInterval(counter);
  }, [endValue, duration]);

  return (
    <h4>
      {count}
      {suffix}
    </h4>
  );
};

const CountersSection = () => {
  return (
    <section className="space-section">
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-3 col-6">
            <div className="counter-box">
              <Counter endValue={3} duration={1000} suffix="+" />
              <p>Years in the industry</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="counter-box">
              <Counter endValue={98} duration={1000} suffix="%" />
              <p>Client Retention</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="counter-box">
              <Counter endValue={12} duration={1000} suffix="+" />
              <p>Countries Served</p>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="counter-box">
              <Counter endValue={25} duration={1000} suffix="+" />
              <p>Projects Delivered</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <button type="button" className="btn btn-orange">Share your Requirements</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountersSection;
