import React from 'react'
import Card from '../../common/card'

const Erp = () => {
	const erpData = [
		{
			id: 1,
			name: 'Data Migration Service',
			description: 'Smoothly transition your data to Infor ERP LN with ease and efficiency.',
			imageUrl:'/images/network.png'
		},
		{
			id: 2,
			name: 'Application Management System',
			description: 'Infor LN Application Management Services optimize and support your ERP system for seamless operations.',
			imageUrl:'images/management.png'
		},
		{
			id: 3,
			name: 'Application Audit System',
			description: 'Achieve peak performance and compliance with our thorough ERP audit services.',
			imageUrl:'/images/audit.png'
		},
	]
  return (
    <section className="space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center mb-4">
					<h1 className="heading">Enhance your <span> ERP </span> using <br/><span>Infor</span> LN.</h1>
					<p>We can manage your ERP solution.</p>
				</div>
				{erpData && erpData.map((eData)=>(
					<div className="col-md-4 d-flex" key={eData.id}>
						<Card data={eData}/>
					</div>
				))}
			</div>
			<div className="row">
				<div className="col-md-12 text-center mt-4">
					<button type="button" className="btn btn-orange">Talk to Infor expert</button>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Erp
