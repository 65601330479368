import React from 'react'
const OurClients = () => {
  const clientData = [
    { id: 1, imageUrl: '/images/icon-8.png' },
    { id: 2, imageUrl: '/images/icon-1.png' },
    { id: 3, imageUrl: '/images/icon-2.png' },
    { id: 4, imageUrl: '/images/icon-3.png' },
    { id: 5, imageUrl: '/images/icon-4.png' },
    { id: 6, imageUrl: '/images/icon-5.png' },
    { id: 7, imageUrl: '/images/icon-6.png' },
    { id: 8, imageUrl: '/images/icon-7.png' },
  ];

  return (
    <section className="space-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="heading">
              We are <span> Trusted </span> across the <br /> globe.
            </h1>
            <p>Our clients.</p>
          </div>
          <div className="col-md-12">
            <ul className="icon-list">
              {clientData.map((cdata) => (
                <li key={cdata.id}>
                  <div className="technology-logo">
                    {
						// eslint-disable-next-line
						<img src={cdata.imageUrl} className="img-fluid" alt={String(cdata.id)} />}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurClients;
