import React from "react";
import AboutCard from "../components/about-page/about-card";
import OurMethodology from "../components/about-page/our-methodology";
import OurValues from "../components/about-page/our-values";
import OurVisions from "../components/about-page/our-visions";
import Banner from "../components/common/banner";

const About = () => {
  const bannerData = {
    headingOne: 'Everything begins with "us."',
    headingTwo: '<b>Improving lives With our technological innovations.</b>',
    description:'<p>Partner with us to unlock the power of <b>custom software solutions</b>, combining expertise and advanced technology for tailored, cutting-edge results.</p>',
    buttonName: 'Talk To Experts',
    imageUrl:'/images/iso-certificate.png',
    className:"about-bg",
  }
  return <>
  <Banner bannerData={bannerData}/>
  <AboutCard/>
  <OurMethodology/>
  <OurValues/>
  <OurVisions/>
  </>;
};

export default About;