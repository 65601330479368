import React from 'react'
import Card from '../../common/card'

const AboutCard = () => {
	const aboutData = [
		{
			id: 1,
			name: 'Established in 2021',
			description:'We stand as a prominent software company, dedicated to serving our valued clients since our founding in 2021.',
			imageUrl: '/images/book-check.png'
		},
		{
			id: 2,
			name: 'Location',
			description:'We have a service headquarter in Indore, India with great innovating minds.',
			imageUrl: '/images/location.png'
		},
		{
			id: 3,
			name: 'Certificates',
			description:'ISO-9001-2015 Certified for Quality.',
			imageUrl: '/images/book-mark.png'
		},
		{
			id: 4,
			name: 'Achievements in Projects',
			description:'25+ projects delivered in 2 years',
			imageUrl: '/images/diploma.png'
		},
		{
			id: 5,
			name: 'Our Area of Expertise',
			description:'Delivering Customized Assistance to Startups and SMEs – Our Expertise.',
			imageUrl: '/images/gem.png'
		},
		{
			id: 6,
			name: 'Software Recognized with Awards',
			description:'We provide resilient, scalable, and software that has earned awards for its excellence.',
			imageUrl: 'images/settings-alt.png'
		}
		
	]
  return (
    <section className="space-section">
		<div className="container">
			<div className="row">
				{aboutData && aboutData.map((aData)=>(
					<div className="col-md-4 d-flex" key={aData.id}>
						<Card data={aData} isCenter={true}/>
					</div>
				))}
			</div>
			
		</div>
	</section>
  )
}

export default AboutCard
