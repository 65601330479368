import React from 'react'

const Ideas = () => {
  return (
    <>
      <section  className="space-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="heading">Fueling <span> Ideas </span> with Ruby on <br/> Rails Trends.</h1>
              <p>Emerging Ruby on Rails trends sWplify decision-making, enhance customer <br/> experiences, and boost productivity.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="rubyrail space-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h6 className="outcome-title">Adoption in different Industries</h6>
              <div className="outcome-description">
                <p>E-commerce</p>
              </div>
              <div className="outcome-description">
                <p>Social Networking</p>
              </div>
              <div className="outcome-description">
                <p>Healthcare & lifestyle</p>
              </div>
              <div className="outcome-description">
                <p>Real Estate</p>
              </div>
              <div className="outcome-description">
                <p>Travel and Hospitality</p>
              </div>
              <div className="outcome-description">
                <p>Enterprise, Retail & Manufacturing</p>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Ideas
