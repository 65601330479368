import React from "react";
import { Link } from "react-router-dom";

// Define the type for bannerData

const Banner = ({ bannerData }) => {
  return (
    <section className={bannerData.className}>
      <div className="container">
        <div className="banner-box">
          <div className="row">
            <div className="col-md-12 mb-5">
              <h6 className="title">{bannerData.headingOne}</h6>
            </div>
            <div className="col-lg-5 col-md-12 col-12">
              <h2 dangerouslySetInnerHTML={{ __html: bannerData.headingTwo }} />
              <div
                dangerouslySetInnerHTML={{ __html: bannerData.description }}
              ></div>
              <Link to={`/contact-us`} className="btn btn-orange m-t-50">
                {bannerData.buttonName}
              </Link>
            </div>
          </div>
        </div>
        {bannerData.className === 'slider-img' && <div className="certificate-img">
          {
            // eslint-disable-next-line
            <img
              src={`${bannerData.imageUrl}`}
              className="img-fluid"
              alt="Certificate"
            />
          }
        </div>}
      </div>
    </section>
  );
};

export default Banner;
