import React from 'react'

const  DataMangement = () => {
  return (
    <section className="services-box space-section">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-6">
            <h6 className="vision-title">Backend | Frontend | DBMS | Webapp</h6>
            <h5 className="methodology-title">ESG Data mangement.</h5>
            <h4 className="demand-delivery">Sustainability management</h4>
            <h6 className="outcome-title">About</h6>
            <p>It is a provider of cloud-based sustainability management software and consulting services that companies and organizations use to achieve sustainability and ESG goals.</p>
            <h6 className="outcome-title">Project Outcomes</h6>
            <div className="outcome-description">
              <p>30% increase in system efficiency and performance.</p>
            </div>
            <div className="outcome-description">
              <p>25% improvement in user experience (UX) satisfaction.</p>
            </div>
            <div className="outcome-description">
              <p>40% growth in user base and platform adoption.</p>
            </div>
            <div className="outcome-description">
              <p>15% reduction in carbon footprint reported by users.</p>
            </div>
            
          </div>
          <div className="col-md-6 text-center">
            <div className="service-slide">
              {// eslint-disable-next-line
              <img src="/images/ecg-management.png" className="img-fluid w-70" alt='ecg'/>}
            </div>
          </div>
        </div>	
      </div>
    </section>
  )
}

export default  DataMangement
