import React from "react";
import FooterForm from "../footer-form";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/contact-us" && <FooterForm />}
      <footer className="space-section footer pb-3">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
              <h1 className="heading text-white text-left mb-4">
                Get in touch.{" "}
              </h1>
              <p>
                We’d love to resolve your queries with personalized assistance.
              </p>
              <ul className="footer-list">
                <li>
                  <div className="contact-icon">
                    {
                      // eslint-disable-next-line
                      <img
                        src="/images/phone.png"
                        className="img-fluid"
                        alt="phone"
                      />
                    }
                    <p>
                      <a
                        href="tel:+919109109148"
                        className="text-white text-decoration"
                      >
                        +91-9109109148
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="contact-icon">
                    {
                      // eslint-disable-next-line
                      <img
                        src="/images/email.png"
                        className="img-fluid"
                        alt="email"
                      />
                    }
                    <p>
                      <a
                        href="mailto:Business@srnext.in"
                        className="text-white text-decoration"
                      >
                        Business@srnext.in
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="contact-icon">
                    {
                      // eslint-disable-next-line
                      <img
                        src="/images/skype.png"
                        className="img-fluid"
                        alt="skype"
                      />
                    }
                    <p><a className="text-white text-decoration" href="skype:live:.cid.e11f1805bc4d0dfe">Skype</a></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
              {
                // eslint-disable-next-line
                <img
                  src="/images/logo-white.png"
                  className="img-fluid logo"
                  alt="logo"
                />
              }
              <p>
                5th floor, Next Coworks, AB Rd, Slice 6, <br /> Aranya Nagar,
                Scheme 78, Vijay Nagar, <br /> Indore, Madhya Pradesh 452010
              </p>
              <ul className="social-icon">
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/sr-next/"> {
                    // eslint-disable-next-line
                    <img
                      src="/images/Linkedin.png"
                      className="img-fluid"
                      alt="linkedin"
                    />
                  }</a>
                 
                </li>
                <li>
                  {
                    // eslint-disable-next-line
                    <img
                      src="/images/Twitter.png"
                      className="img-fluid"
                      alt="twitter"
                    />
                  }
                </li>
                <li>
                  {
                    // eslint-disable-next-line
                    <img
                      src="/images/Facebook.png"
                      className="img-fluid"
                      alt="facebook"
                    />
                  }
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              <p>Copyright © 2024 SRNext. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
