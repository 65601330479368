import React from "react";
import Card from "../../common/card";

const OurPotential = () => {
  const potentialData = [
    {
      id: 1,
      name: "Convention Over Configuration",
      description:
        "Rails speeds up development by prioritizing conventions over configurations, allowing developers to start with minimal setup.",
      imageUrl: "/images/resize.png",
    },
    {
      id: 2,
      name: "Scalability",
      description:
        "Consider the success of GitHub, Airbnb, Kickstarter, Zendesk, and Bloomberg—these companies effectively scale their applications using Rails.",
      imageUrl: "/images/stats.png",
    },
    {
      id: 3,
      name: "Robust Ecosystem",
      description:
        "Rails has a large, active community that provides numerous libraries (gems), allowing developers to add features without building from scratch.",
      imageUrl: "/images/layout-fluid.png",
    },
    {
      id: 4,
      name: "MVC Architecture",
      description:
        "Rails uses the MVC architecture to separate the application into three layers, simplifying management and maintenance.",
      imageUrl: "/images/pulse.png",
    },
    {
      id: 5,
      name: "Built-In Testing Framework",
      description:
        "Rails includes a built-in testing framework, encouraging test-driven development (TDD). This can lead to more reliable and maintainable code.",
      imageUrl: "/images/grid.png",
    },
    {
      id: 6,
      name: "Support for RESTful Applications",
      description:
        "Rails supports RESTful application design, making it a good choice for developing APIs and web services.",
      imageUrl: "/images/resources.png",
    },
  ];
  return (
    <section className="space-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-4">
            <h1 className="heading">
              Ruby on Rails <span> excels</span> in product <br /> development.
            </h1>
            <p>Discovery our potential</p>
          </div>
          {potentialData &&
            potentialData.map((pData) => (
              <div className="col-md-4 d-flex" key={pData.id}>
                <Card data={pData} isCenter={true} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default OurPotential;
