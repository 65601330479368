import React from 'react'

const OurVisions = () => {
  return (
    <section className="visions space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-5">
					<h6 className="vision-title">Founder’s Desk</h6>
					<h1 className="heading text-left">Explore our <span> Visions. </span></h1>
					<p>At SR NEXT, we are driven by a passion for delivering top-notch software solutions using the latest technology. Every year, we leverage technology and design to simplify communication, humanize brands, and boost profitability for our clients. </p>
					<p>From day one, we've been committed to offering exceptional solutions across digital, physical, and convergent spaces. </p>
					<p> 
						Our goal is to create remarkable experiences by transforming business models and integrating innovation into every product. Inclusivity is at the heart of our culture, enhancing employee performance, engagement, and productivity. 
					</p>
					<p>
						We're focused on exceeding customer expectations in a rapidly evolving global economy and contributing to sustainable global success. We look forward to partnering with you to create something extraordinary at SR NEXT.
					</p>
					<div className="author-box">
						<div className="author-img">
							{// eslint-disable-next-line
							<img src="/images/persion.png" alt='persion'/>}
						</div>
						<div className="author"></div>
						<p>Sincerely,<br/> PALAK GUPTA <br/> Founder</p>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default OurVisions
