import React from 'react'

const ECommerce = () => {
  return (
    <section className="services-box space-section bg-gray">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-6">
            <h6 className="vision-title">Backend | Frontend | UI/UX</h6>
            <h5 className="methodology-title">E-commerce.</h5>
            <h4 className="demand-delivery">On demand delivery</h4>
            <h6 className="outcome-title">About</h6>
            <p>It is Singapore's leading online liquor store, specializing in delivering a wide range of premium alcohol brands right to your doorstep. </p>
            <h6 className="outcome-title">Project Outcomes</h6>
            <div className="outcome-description">
              <p>95% customer satisfaction, 40% increase in repeat purchases.</p>
            </div>
            <div className="outcome-description">
              <p>30% rise in returning customers due to improved loyalty.</p>
            </div>
            <div className="outcome-description">
              <p>90% of customers rate 4+ stars, enhancing brand trust.</p>
            </div>
            <div className="outcome-description">
              <p>20% market expansion through scalable growth.</p>
            </div>
            
          </div>
          <div className="col-md-6 text-center">
            <div className="service-slide">
              {// eslint-disable-next-line
              <img src="/images/ecommerce.png" className="img-fluid w-70" alt='ecommerce'/>}
            </div>
          </div>
        </div>	
      </div>
    </section>
  )
}

export default ECommerce
