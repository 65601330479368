import React from 'react';
const Card = ({ data, isCenter = false }) => {
  return (
    <div className={`erp-solution ${isCenter ? 'text-center' : ''}`}>
      <div className={`icon ${isCenter ? 'max-auto' : ''}`}>
        {// eslint-disable-next-line
        <img src={data.imageUrl} className="img-fluid" alt={String(data.id)} />}
      </div>
      <h5>{data.name}</h5>
      <p>{data.description}</p>
    </div>
  );
};

export default Card;
