import React from "react";
import ContactForm from "../../common/contact-form";

const ContactBanner = () => {
  return (
    <section className="contact-bg">
      <div className="container">
        <div className="banner-box">
          <div className="row">
            <div className="col-md-6 mb-5"></div>
            <div className="col-lg-6 col-md-12 col-12">
              <ContactForm isLight={true} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBanner;
