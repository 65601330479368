import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  // Get the current URL from the router object

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="">
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  {
                    // eslint-disable-next-line
                    <img src="/images/logo.png" alt="logo" />
                  }
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo03"
                  aria-controls="navbarTogglerDemo03"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarTogglerDemo03"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          `nav-link ${isActive ? "text-red" : ""}`
                        }
                        to="/about"
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          `nav-link ${isActive ? "text-red" : ""}`
                        }
                        to="/our-work"
                      >
                        Our Work
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          `nav-link ${isActive ? "text-red" : ""}`
                        }
                        to="/services"
                      >
                        Services
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          `nav-link ${isActive ? "text-red" : ""}`
                        }
                        to="/contact-us"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                  <Link to="/contact-us" className="btn btn-orange">
                    Contact us
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
