import React from 'react';

const ImageBox = ({ data }) => {
  return (
    <div className="service-slide">
      {// eslint-disable-next-line
      <img src={data.imageUrl} className="img-fluid" alt={String(data.id)} />}
      <div className="caption-img">
        <h5 dangerouslySetInnerHTML={{ __html: data.name }}></h5>
        <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
      </div>
    </div>
  );
};
export default ImageBox
