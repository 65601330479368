import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const LatestCase = () => {
	const responsive = {
		superLargeDesktop: {
		  // the naming can be any, depends on you.
		  breakpoint: { max: 4000, min: 3000 },
		  items: 5
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 4
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };
  return (
	<section className="space-section bg-blue">
	<div className="container-fluid">
		<div className="row">
			<div className="col-md-12 text-center mb-4">
				<h1 className="heading text-white">They <span>Win.</span> You Can too<span>.</span></h1>
				<p className="text-white">Our latest case studies.</p>
			</div>
			</div>
		<Carousel  
			swipeable={false}
			draggable={true}
			showDots={false}
			responsive={responsive}
			ssr={true} // means to render carousel on server-side.
			infinite={true}
			autoPlay={ true}
			autoPlaySpeed={1500}
			keyBoardControl={true}
			customTransition="all .5"
			transitionDuration={500}
			containerClass="carousel-container"
			removeArrowOnDeviceType={["tablet", "mobile"]}
			deviceType={'desktop'}
			dotListClass="custom-dot-list-style"
			itemClass="carousel-item-padding-40-px"
		>
			<div className="studies-box">
				{// eslint-disable-next-line
				<img src="/images/s1.png" className="img-fluid" alt='s1'/>}
				<div className="caption-slide">
					<h5>E-commerce Solution</h5>
					<p>Backend | Design</p>
				</div>
			</div>
			<div className="studies-box">
				{// eslint-disable-next-line
				<img src="/images/s4.png" className="img-fluid" alt='s4'/>}
				<div className="caption-slide">
					<h5>E-commerce Solution</h5>
					<p>Backend | Design</p>
				</div>
			</div>
			<div className="studies-box">
				{// eslint-disable-next-line
				<img src="/images/s3.png" className="img-fluid" alt='s3'/>}
				<div className="caption-slide">
					<h5>ERP Solutions</h5>
					<p>Frontend | Backend | Design</p>
				</div>
			</div>
			<div className="studies-box">
				{// eslint-disable-next-line
				<img src="/images/s2.png" className="img-fluid" alt='s2'/>}
				<div className="caption-slide">
					<h5>Sustainable Solutions</h5>
					<p>Research | Backend | Design</p>
				</div>
			</div>
		</Carousel>
	
		<div className="row">
			<div className="col-md-12 text-center">
				<Link to={`/our-work`} className="btn btn-orange">View our Portfolio</Link>
			</div>
		</div>
	</div>
</section>
  )
}

export default LatestCase
