import React from 'react'
import ImageBox from '../../common/image-box'

const Services = () => {
	const serviceData= [
		{
			id: 1,
			name: 'Web <br/> Development',
			description:"<p>Helping startups and enterprises create digital experiences across desktop, web, and mobile platforms.</p>",
			imageUrl:'/images/1.png'
		},
		{
			id: 2,
			name: 'App <br/> Development',
			description:"<p>Drive growth with our mobile app development, aligned to business goals and user experiences.</p>",
			imageUrl:'/images/2.png'
		},
		{
			id: 3,
			name: 'Custom Software <br/> Development',
			description:"<p>Build advanced, scalable digital solutions with a top custom software company, ensuring originality.</p>",
			imageUrl:'/images/3.png'
		},
		{
			id: 4,
			name: 'UI/UX <br/> Design',
			description:"<p>Boost engagement and sales with our UI/UX design and strategic consulting.</p>",
			imageUrl:'/images/4.png'
		},
		{
			id: 5,
			name: 'Digital <br/> Transformation',
			description:"<p>Modernize your corporate processes with our digital transformation services.</p>",
			imageUrl:'/images/6.png'
		},
		{
			id: 6,
			name: 'MVP <br/> Development',
			description:"<p>Turn your product into reality affordably with our end-to-end MVP development.</p>",
			imageUrl:'/images/5.png'
		}
	]
  return (
    <section className="services-box space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<h1 className="heading">We Offer a Wide <br/> Variety of <span> IT Services.</span></h1>
				</div>
			</div>
			<div className="row">
				{serviceData && serviceData.map((sData)=>(
					<div className="col-md-4 d-flex" key={sData.id}>
						<ImageBox data={sData}/>
					</div>
				))}
			</div>	
		</div>
	</section>
  )
}

export default Services
